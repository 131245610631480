import { createPinia, defineStore } from "pinia";
import swal from "sweetalert";
export const useCartStore = defineStore("cart", {
  state: () => ({
    cartItems: [],
    couponCodeData: {
      codeApplied: false,
      id: null,
      discount_value: null,
      discount_type: null,
      discountCode: null,
      type: null,
    },
    shippingRate: 0,
    tax: {
      isTax: false,
      state_id: null,
      tax_value: null,
    },
    allProducts: [],
  }),
  persist: true,
  getters: {
    countCartItems: (state) => state.cartItems.length,
    getCartItems: (state) => state.cartItems,
    getCartCoupon: (state) => state.couponCodeData.codeApplied,
    getCartCouponCode: (state) => state.couponCodeData.discountCode,
    getCartCouponCodeId: (state) => state.couponCodeData.id,
    getCartCountQuantity: (state) => {
      let i = 0;
      let totalQty = 0;
      let cartAll = state.cartItems;
      for (i = 0; i < state.cartItems.length; i++) {
        totalQty += state.cartItems[i].quantity;
      }
      return totalQty;
    },
    getCartPurchaseType: (state) => {
      let i = 0;
      let totalQty = 0;
      let cartAll = state.cartItems;
      for (i = 0; i < state.cartItems.length; i++) {
        if(state.cartItems[i].purchaseType == 2){
          return true;
        }
      }
      return false;

      
    },
    getCartTotalPrice: (state) => {
      let i = 0;
      let totalPrice = 0;
      let cartAll = state.cartItems;
      for (i = 0; i < state.cartItems.length; i++) {
        totalPrice += state.cartItems[i].quantity * state.cartItems[i].price;
      }
      (totalPrice * state.tax.tax_value) / 100;
      return (
        parseFloat(totalPrice) 
        //+
        //parseFloat((totalPrice * state.tax.tax_value) / 100) +
       // parseFloat(state.shippingRate)
      ).toFixed(2);
    },

    getDiscountTotal: (state) => {
      let i = 0;
      let totalPrice = 0;
      let cartAll = state.cartItems;
      for (i = 0; i < state.cartItems.length; i++) {
        if (state.couponCodeData.codeApplied) {
          totalPrice += state.cartItems[i].quantity * state.cartItems[i].sale_price;
        }
        else{
          totalPrice += state.cartItems[i].quantity * state.cartItems[i].price;
        }
        
      }
      if (state.couponCodeData.codeApplied) {
        if (
          state.couponCodeData.discount_type == "percentage" &&
          state.couponCodeData.type == "product"
        ) {
          return (
            (totalPrice * state.couponCodeData.discount_value) /
            100
          ).toFixed(2);
        } else if (
          state.couponCodeData.discount_type == "percentage" &&
          state.couponCodeData.type == "shipping"
        ) {
          return (
            (state.shippingRate * state.couponCodeData.discount_value) /
            100
          ).toFixed(2);
        } else {
          return (
            totalPrice.toFixed(2) - state.couponCodeData.discount_value
          ).toFixed(2);
        }
      } else {
        return 0;
      }
    },

    getTaxTotal: (state) => {
      let i = 0;
      let totalPrice = 0;
      let cartAll = state.cartItems;
      for (i = 0; i < state.cartItems.length; i++) {
        totalPrice += state.cartItems[i].quantity * state.cartItems[i].price;
      }

      if (state.tax.isTax) {
        return ((totalPrice * state.tax.tax_value) / 100).toFixed(2);
      } else {
        return 0;
      }
    },

    getCartTotalNetPrice: (state) => {
      let i = 0;
      let totalPrice = 0;
      let shippingDiscount = 0;
      let productTotalDiscount = 0;
      let cartAll = state.cartItems;
      for (i = 0; i < state.cartItems.length; i++) {
        if (state.couponCodeData.codeApplied) {
          totalPrice += state.cartItems[i].quantity * state.cartItems[i].price;
        }
        else{
          totalPrice += state.cartItems[i].quantity * state.cartItems[i].price;
        }
        
      }

      let totalTax = 0;

      if (state.tax.isTax) {
        totalTax = parseFloat((totalPrice * state.tax.tax_value) / 100).toFixed(
          2
        );
      }

      if (state.couponCodeData.codeApplied) {
        if (
          state.couponCodeData.discount_type == "percentage" &&
          state.couponCodeData.type == "shipping"
        ) {
          console.log("check shippingRate data :");
          shippingDiscount =
            (state.shippingRate) * (state.couponCodeData.discount_value / 100);
          console.log("check shippingRate data : ", shippingDiscount);
          //state.shippingRate = state.shippingRate - shippingDiscount;
        } else if (
          state.couponCodeData.discount_type == "percentage" &&
          state.couponCodeData.type == "product"
        ) {
          productTotalDiscount = parseFloat((totalPrice * state.couponCodeData.discount_value) / 100);
          totalPrice = parseFloat(totalPrice) - parseFloat(productTotalDiscount);
          totalPrice = parseFloat(totalPrice).toFixed(2);
        } else if (
          state.couponCodeData.discount_type == "value" &&
          state.couponCodeData.type == "product"
        ) {
          totalPrice = parseFloat(totalPrice) - state.couponCodeData;
        } else if (
          state.couponCodeData.discount_type == "value" &&
          state.couponCodeData.type == "shipping"
        ) {
          shippingDiscount = state.couponCodeData.discount_value;
          //state.shippingRate = state.shippingRate - shippingDiscount;
        } else {
          //console.log("coupn net else:");
          //return state.shippingRate;
        }
      }
      console.log("state.couponCodeData.discount_value", state.couponCodeData.discount_value);
      console.log("productTotalDiscount : ", productTotalDiscount); 
      console.log("totalPrice : ", totalPrice);
      console.log("state.shippingRate  : ", state.shippingRate);
      console.log("shippingDiscount : ", shippingDiscount);
      console.log("totalTax : ", totalTax);

      let shippingTax = 0;
       if (state.tax.isTax){
        shippingTax =  ((state.shippingRate * state.tax.tax_value) / 100).toFixed(2);
      } else {
        shippingTax = 0;
      }

      return (
        parseFloat(totalPrice) +
        parseFloat(state.shippingRate - shippingDiscount) +
        parseFloat(totalTax) +
        parseFloat(shippingTax)
      ).toFixed(2);
      // else {
      //   console.log("net else:");
      //   return state.shippingRate;
      // }
    },
    getCandaSates: (state) => state.canadaStates,
    getUnitedStates: (state) => state.unitedStates,
    getShippingRate: (state) => {
      let shippingDiscount = 0;
      if (state.couponCodeData.codeApplied) {
        if (
          state.couponCodeData.discount_type == "percentage" &&
          state.couponCodeData.type == "shipping"
        ) {
          return state.shippingRate;

          // return (
          //   state.shippingRate -
          //   (state.shippingRate * state.couponCodeData.discount_value) / 100
          // ).toFixed(2);
        } else {
          return state.shippingRate;
        }
      } else {
        return state.shippingRate;
      }
    },
    getShippingTax: (state) => {


       if (state.tax.isTax) {
        return ((state.shippingRate * state.tax.tax_value) / 100).toFixed(2);
      } else {
        return 0;
      }


      // let shippingDiscount = 0;
      // if (state.couponCodeData.codeApplied) {
      //   if (
      //     state.couponCodeData.discount_type == "percentage" &&
      //     state.couponCodeData.type == "shipping"
      //   ) {

         

      //     return state.shippingRate;

      //     // return (
      //     //   state.shippingRate -
      //     //   (state.shippingRate * state.couponCodeData.discount_value) / 100
      //     // ).toFixed(2);
      //   } else {
      //   }
      // } else {
      //   return state.shippingRate;
      // }
    },
    getShippingDiscount: (state) => {
      let shippingDiscount = 0;
      if (state.couponCodeData.codeApplied) {
        if (
          state.couponCodeData.discount_type == "percentage" &&
          state.couponCodeData.type == "shipping"
        ) {
          return (
            (state.shippingRate * state.couponCodeData.discount_value) /
            100
          ).toFixed(2);
        } else {
        }
      } else {
        return shippingDiscount;
      }
    },
  },
  actions: {
    addAllProducts(productSingle) {
      let index = this.allProducts.findIndex(
        (product) => product.id === productSingle.id
      );
      if (index > -1) {
        this.allProducts.splice(index, 1);
        
      }
      this.allProducts.push(productSingle);
    },
    addTaxData(taxData) {
      this.tax.isTax = true;
      this.tax.state_id = taxData.state_id;
      this.tax.tax_value = taxData.tax_value;
    },
    applyCoupon(coupon, discountCode) {
      this.couponCodeData.codeApplied = true;
      this.couponCodeData.id = coupon.id;
      this.couponCodeData.discount_value = coupon.discount_value;
      this.couponCodeData.discount_type = coupon.discount_type;
      this.couponCodeData.discountCode = discountCode;
      this.couponCodeData.type = coupon.type;
    },
    resetApplyCoupon() {
      this.couponCodeData.codeApplied = false;
      this.couponCodeData.id = null;
      this.couponCodeData.discount_value = null;
      this.couponCodeData.discount_type = null;
      this.couponCodeData.discountCode = null;
      this.couponCodeData.type = null;
    },
    addShippingRate(rate) {
      this.shippingRate = rate;
    },
    checkRangeFun(rangeData, quantity) {
      let checkFlag = false;
      if (rangeData.length == 2) {
        if (
          quantity >= parseInt(rangeData[0]) &&
          quantity <= parseInt(rangeData[1])
        ) {
          checkFlag = true;
        }
        // for (var i = parseInt(rangeData[0]); i <= parseInt(rangeData[1]); i++) {
        //   if (i == quantity) {
        //     console.log("quantity chec ", i);
        //     checkFlag = true;
        //   }
        // }
      } else if (rangeData.length == 1) {
        if (quantity >= parseInt(rangeData[0])) {
          checkFlag = true;
        }
      } else {
        console.log("jjjjjjjjjjjjjj");
      }

      return checkFlag;
    },
    addToCart(item) {
      let index = this.cartItems.findIndex((product) => product.id === item.id);
      if (index !== -1) {
        console.log("here", item);
        //this.products[index].quantity += 1;
        this.cartItems[index].price = item.price;
        this.cartItems[index].quantity += item.quantity;
        this.cartItems[index].purchaseType = item.purchaseType;
        this.cartItems[index].sale_price = item.sale_price;

        console.log("nee ", this.cartItems);
        //   swal({
        //         position: 'top-end',
        //         text: "Your item has been updated",
        //         icon: "success",
        //         closeOnClickOutside: false,
        //     });
      } else {
        console.log("thhhhere", item);
        // item.quantity = 1;
        this.cartItems.push(item);
        // swal({
        //     position: 'top-end',
        //     text: "Your item has been saved",
        //     icon: "success",
        //     closeOnClickOutside: false,
        // });
      }
    },
    incrementQ(item, checkIsLoggedIn, getUserUserData) {

      
      let index = this.cartItems.findIndex((product) => product.id === item.id);
      if (index !== -1) {
        let productListIindex = this.allProducts.findIndex(
          (productSingle) => productSingle.id === item.id
        );

        this.cartItems[index].quantity += 1;

        let range1 = [];
        let range2 = [];
        let range3 = [];
        let totalQuantity = this.cartItems[index].quantity;
        let productPrice = 0;
        //if (index !== -1) {
        range1 = this.allProducts[productListIindex].qty_range_1
          .replace("+", "")
          .split("-");
        range2 = this.allProducts[productListIindex].qty_range_2
          .replace("+", "")
          .split("-");
        range3 = this.allProducts[productListIindex].qty_range_3
          .replace("+", "")
          .split("-");
        //totalQuantity += 1;
        //}

        let rangeWhich1 = this.checkRangeFun(range1, totalQuantity);
        let rangeWhich2 = this.checkRangeFun(range2, totalQuantity);
        let rangeWhich3 = this.checkRangeFun(range3, totalQuantity);

        // Customer Range
        let customerRange1 = this.allProducts[
          productListIindex
        ].cust_qty_range_1
          .replace("+", "")
          .split("-");
        let customerRange2 = this.allProducts[
          productListIindex
        ].cust_qty_range_2
          .replace("+", "")
          .split("-");
        let customerRange3 = this.allProducts[
          productListIindex
        ].cust_qty_range_3
          .replace("+", "")
          .split("-");
        // Customer Range Ends Here
        let customerRangeWhich1 = this.checkRangeFun(
          customerRange1,
          totalQuantity
        );
        let customerRangeWhich2 = this.checkRangeFun(
          customerRange2,
          totalQuantity
        );
        let customerRangeWhich3 = this.checkRangeFun(
          customerRange3,
          totalQuantity
        );
        console.log("rangeWhich1 ", rangeWhich1);
        console.log("rangeWhich2 ", rangeWhich2);
        console.log("rangeWhich3 ", rangeWhich3);

        if (checkIsLoggedIn) {

          
          if (getUserUserData.user_type == "ambassador") {
           
            if (this.cartItems[index].purchaseType == 1) {
              productPrice = this.allProducts[productListIindex].amb_sale_price;
            } else if (this.cartItems[index].purchaseType == 2) {
              productPrice =
                this.allProducts[productListIindex].autoship_price_amb;
            } else {
              productPrice = this.allProducts[productListIindex].amb_sale_price;
            }
            if (this.cartItems[index].purchaseType != 2) {
              if (rangeWhich1) {
                if (this.allProducts[productListIindex].qty_range_1_per > 0) {
                  productPrice =
                    productPrice -
                    (productPrice *
                      this.allProducts[productListIindex].qty_range_1_per) /
                      100;
                }
              } else if (rangeWhich2) {
                if (this.allProducts[productListIindex].qty_range_2_per > 0) {
                  productPrice =
                    productPrice -
                    (productPrice *
                      this.allProducts[productListIindex].qty_range_2_per) /
                      100;
                }
              } else if (rangeWhich3) {
                if (this.allProducts[productListIindex].qty_range_3_per > 0) {
                  productPrice =
                    productPrice -
                    (productPrice *
                      this.allProducts[productListIindex].qty_range_3_per) /
                      100;
                }
              }
            }
          } else if (getUserUserData.user_type == "scholar") {

            console.log("indeside scholar",this.cartItems[index].purchaseType);
            if (this.cartItems[index].purchaseType == 1) {
              productPrice = this.allProducts[productListIindex].sc_sale_price;
            } else if (this.cartItems[index].purchaseType == 2) {
              productPrice =
                this.allProducts[productListIindex].autoship_price_sc;
            } else {
              productPrice = this.allProducts[productListIindex].sc_sale_price;
            }
            console.log("Single Product Price from cart",productPrice);
            if (this.cartItems[index].purchaseType != 2) {
              if (rangeWhich1) {
                if (this.allProducts[productListIindex].qty_range_1_per > 0) {
                  productPrice =
                    productPrice -
                    (productPrice *
                      this.allProducts[productListIindex].qty_range_1_per) /
                      100;
                }
                console.log("Scholar Range1",this.allProducts[productListIindex].qty_range_1_per);
              } else if (rangeWhich2) {
                if (this.allProducts[productListIindex].qty_range_2_per > 0) {
                  productPrice =
                    productPrice -
                    (productPrice *
                      this.allProducts[productListIindex].qty_range_2_per) /
                      100;
                }
                console.log("Scholar Range2",this.allProducts[productListIindex].qty_range_2_per);
              } else if (rangeWhich3) {
                if (this.allProducts[productListIindex].qty_range_3_per > 0) {
                  productPrice =
                    productPrice -
                    (productPrice *
                      this.allProducts[productListIindex].qty_range_3_per) /
                      100;
                }
                console.log("Scholar Range3",this.allProducts[productListIindex].qty_range_3_per);
              }
            }

          } else {
            if (this.cartItems[index].purchaseType == 1) {
              productPrice = this.allProducts[productListIindex].sale_price;
            } else if (this.cartItems[index].purchaseType == 2) {
              productPrice =
                this.allProducts[productListIindex].autoship_price_customer;
            } else {
              productPrice = this.allProducts[productListIindex].sale_price;
            }
            console.log("increment price before discount ",productPrice)
            console.log("this.cartItems[index] ", this.cartItems[index])
           
            if (this.cartItems[index].purchaseType != 2) {

            if (customerRangeWhich1) {
              if (this.allProducts[productListIindex].cust_qty_range_1_per > 0) {
                productPrice =
                  productPrice -
                  (productPrice *
                    this.allProducts[productListIindex].cust_qty_range_1_per) /
                    100;
              }
            } else if (customerRangeWhich2) {
              if (this.allProducts[productListIindex].cust_qty_range_2_per > 0) {
                productPrice =
                  productPrice -
                  (productPrice *
                    this.allProducts[productListIindex].cust_qty_range_2_per) /
                    100;
              }
            } else if (customerRangeWhich3) {
              if (this.allProducts[productListIindex].cust_qty_range_3_per > 0) {
                productPrice =
                  productPrice -
                  (productPrice *
                    this.allProducts[productListIindex].cust_qty_range_3_per) /
                    100;
              }
            }
          }

          }

          //let productImage = "";
          // if (productSingle.value.images_url.length > 0) {
          //   productImage = productSingle.value.images_url[0];
          // }
        } else {
          console.log("indehere else");
          if (this.cartItems[index].purchaseType == 1) {
            productPrice = this.allProducts[productListIindex].sale_price;
          } else if (this.cartItems[index].purchaseType == 2) {
            productPrice =
              this.allProducts[productListIindex].autoship_price_customer;
          } else {
            productPrice = this.allProducts[productListIindex].sale_price;
          }
          if (this.cartItems[index].purchaseType != 2) {

            if (customerRangeWhich1) {
              if (this.allProducts[productListIindex].cust_qty_range_1_per > 0) {
                productPrice =
                  productPrice -
                  (productPrice *
                    this.allProducts[productListIindex].cust_qty_range_1_per) /
                    100;
              }
            } else if (customerRangeWhich2) {
              if (this.allProducts[productListIindex].cust_qty_range_2_per > 0) {
                productPrice =
                  productPrice -
                  (productPrice *
                    this.allProducts[productListIindex].cust_qty_range_2_per) /
                    100;
              }
            } else if (customerRangeWhich3) {
              if (this.allProducts[productListIindex].cust_qty_range_3_per > 0) {
                productPrice =
                  productPrice -
                  (productPrice *
                    this.allProducts[productListIindex].cust_qty_range_3_per) /
                    100;
              }
            }
          }
        }

        console.log("productPrice ", productPrice);
        this.cartItems[index].price = productPrice;
        // swal({
        //     position: 'top-end',
        //     icon: 'success',
        //     title: 'Your item has been updated',
        //     showConfirmButton: false,
        //     timer: 1500
        // });
      }
    },
    decrementQ(item, checkIsLoggedIn, getUserUserData) {
      let index = this.cartItems.findIndex((product) => product.id === item.id);
      if (index !== -1) {
        if (this.cartItems[index].quantity != 1) {
          this.cartItems[index].quantity -= 1;

          let productListIindex = this.allProducts.findIndex(
            (productSingle) => productSingle.id === item.id
          );

          //this.cartItems[index].quantity += 1;

          let range1 = [];
          let range2 = [];
          let range3 = [];
          let totalQuantity = this.cartItems[index].quantity;
          let productPrice = 0;
          //if (index !== -1) {
          range1 = this.allProducts[productListIindex].qty_range_1
            .replace("+", "")
            .split("-");
          range2 = this.allProducts[productListIindex].qty_range_2
            .replace("+", "")
            .split("-");
          range3 = this.allProducts[productListIindex].qty_range_3
            .replace("+", "")
            .split("-");
          //totalQuantity += 1;
          //}

          let rangeWhich1 = this.checkRangeFun(range1, totalQuantity);
          let rangeWhich2 = this.checkRangeFun(range2, totalQuantity);
          let rangeWhich3 = this.checkRangeFun(range3, totalQuantity);

          // Customer Range
          let customerRange1 = this.allProducts[
            productListIindex
          ].cust_qty_range_1
            .replace("+", "")
            .split("-");
          let customerRange2 = this.allProducts[
            productListIindex
          ].cust_qty_range_2
            .replace("+", "")
            .split("-");
          let customerRange3 = this.allProducts[
            productListIindex
          ].cust_qty_range_3
            .replace("+", "")
            .split("-");
          // Customer Range Ends Here
          let customerRangeWhich1 = this.checkRangeFun(
            customerRange1,
            totalQuantity
          );
          let customerRangeWhich2 = this.checkRangeFun(
            customerRange2,
            totalQuantity
          );
          let customerRangeWhich3 = this.checkRangeFun(
            customerRange3,
            totalQuantity
          );
          console.log("rangeWhich1 ", rangeWhich1);
          console.log("rangeWhich2 ", rangeWhich2);
          console.log("rangeWhich3 ", rangeWhich3);

          if (checkIsLoggedIn) {
            if (getUserUserData.user_type == "ambassador") {
              if (this.cartItems[index].purchaseType == 1) {
                productPrice =
                  this.allProducts[productListIindex].amb_sale_price;
              } else if (
                this.cartItems[index].purchaseType == 2
              ) {
                productPrice =
                  this.allProducts[productListIindex].autoship_price_amb;
              } else {
                productPrice =
                  this.allProducts[productListIindex].amb_sale_price;
              }
            if (this.cartItems[index].purchaseType != 2) {
                if (rangeWhich1) {
                  if (this.allProducts[productListIindex].qty_range_1_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice *
                        this.allProducts[productListIindex].qty_range_1_per) /
                        100;
                  }
                } else if (rangeWhich2) {
                  if (this.allProducts[productListIindex].qty_range_2_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice *
                        this.allProducts[productListIindex].qty_range_2_per) /
                        100;
                  }
                } else if (rangeWhich3) {
                  if (this.allProducts[productListIindex].qty_range_3_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice *
                        this.allProducts[productListIindex].qty_range_3_per) /
                        100;
                  }
                }

              }
            } else if (getUserUserData.user_type == "scholar") {
              if (this.cartItems[index].purchaseType == 1) {
                productPrice =
                  this.allProducts[productListIindex].sc_sale_price;
              } else if (
                this.cartItems[index].purchaseType == 2
              ) {
                productPrice =
                  this.allProducts[productListIindex].autoship_price_sc;
              } else {
                productPrice =
                  this.allProducts[productListIindex].sc_sale_price;
              }
              if (this.cartItems[index].purchaseType != 2) {
                  if (rangeWhich1) {
                  if (this.allProducts[productListIindex].qty_range_1_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice *
                        this.allProducts[productListIindex].qty_range_1_per) /
                        100;
                  }
                } else if (rangeWhich2) {
                  if (this.allProducts[productListIindex].qty_range_2_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice *
                        this.allProducts[productListIindex].qty_range_2_per) /
                        100;
                  }
                } else if (rangeWhich3) {
                  if (this.allProducts[productListIindex].qty_range_3_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice *
                        this.allProducts[productListIindex].qty_range_3_per) /
                        100;
                  }
                }
              }
              
            } else {
              if (this.cartItems[index].purchaseType == 1) {
                productPrice = this.allProducts[productListIindex].sale_price;
              } else if (
                this.cartItems[index].purchaseType == 2
              ) {
                productPrice =
                  this.allProducts[productListIindex].autoship_price_customer;
              } else {
                productPrice = this.allProducts[productListIindex].sale_price;
              }
              console.log("price before discount ",productPrice)
              if (this.cartItems[index].purchaseType != 2) {

                if (customerRangeWhich1) {
              if (
                this.allProducts[productListIindex].cust_qty_range_1_per > 0
              ) {
                productPrice =
                  productPrice -
                  ((productPrice *
                    this.allProducts[productListIindex].cust_qty_range_1_per) /
                    100).toFixed(2);
              }
            } else if (customerRangeWhich2) {
              if (
                this.allProducts[productListIindex].cust_qty_range_2_per > 0
              ) {
                productPrice =
                  productPrice -
                  ((productPrice *
                    this.allProducts[productListIindex].cust_qty_range_2_per) /
                    100).toFixed(2);;
              }
            } else if (customerRangeWhich3) {
              if (
                this.allProducts[productListIindex].cust_qty_range_3_per > 0
              ) {
                productPrice =
                  productPrice -
                  ((productPrice *
                    this.allProducts[productListIindex].cust_qty_range_3_per) /
                    100).toFixed(2);;
              }
            }

              }

              
            }

            //let productImage = "";
            // if (productSingle.value.images_url.length > 0) {
            //   productImage = productSingle.value.images_url[0];
            // }
          } else {
            if (this.cartItems[index].purchaseType == 1) {
              productPrice = this.allProducts[productListIindex].sale_price;
            } else if (this.cartItems[index].purchaseType == 2) {
              productPrice =
                this.allProducts[productListIindex].autoship_price_customer;
            } else {
              productPrice = this.allProducts[productListIindex].sale_price;
            }
          if (this.cartItems[index].purchaseType != 2) {
              if (customerRangeWhich1) {
                if (
                  this.allProducts[productListIindex].cust_qty_range_1_per > 0
                ) {
                  productPrice =
                    productPrice -
                    ((productPrice *
                      this.allProducts[productListIindex].cust_qty_range_1_per) /
                      100).toFixed(2);
                }
              } else if (customerRangeWhich2) {
                if (
                  this.allProducts[productListIindex].cust_qty_range_2_per > 0
                ) {
                  productPrice =
                    productPrice -
                    ((productPrice *
                      this.allProducts[productListIindex].cust_qty_range_2_per) /
                      100).toFixed(2);;
                }
              } else if (customerRangeWhich3) {
                if (
                  this.allProducts[productListIindex].cust_qty_range_3_per > 0
                ) {
                  productPrice =
                    productPrice -
                    ((productPrice *
                      this.allProducts[productListIindex].cust_qty_range_3_per) /
                      100).toFixed(2);;
                }
              }

            }
          }

          console.log("productPrice ", productPrice);
          this.cartItems[index].price = productPrice;

          // New code Ends here
        } else {
          swal({
            position: "top-end",
            icon: "error",
            title: "Quantity can not be zero.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    resetAllData() {
      this.cartItems = [];
      this.couponCodeData = {
        codeApplied: false,
        id: null,
        discount_value: null,
        discount_type: null,
        discountCode: null,
      };
      (this.shippingRate = 0),
        (this.tax = {
          isTax: false,
          state_id: null,
          tax_value: null,
        });
      this.allProducts = [];
    },
    removeFromCart(item) {
      console.log("item : ", item);
      // alert("here");
      this.cartItems = this.cartItems.filter(
        (product) => product.id !== item.id
      );
      if (this.cartItems.length == 0) {
        this.resetAllData();
      }
      swal({
        position: "top-end",
        icon: "success",
        title: "Your item has been removed",
        showConfirmButton: false,
        timer: 1500,
      });
    },
  },
});
